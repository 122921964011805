import React, { Component } from 'react'
import { Link } from "react-router-dom"
import styles from './footer.scss'

class Footer extends Component {
  render() {
    return (
      <div className={styles.footer}>
      	<Link to="/datenschutzerklaerung">Datenschutz</Link>
      	<Link to="/impressum">Impressum</Link>
      	<span>&copy; Techunity GmbH 2021</span>
      	<Link to="/">Techunity GmbH</Link>
      </div>
    )
  }
}

export default Footer;