import React, { Component } from 'react'
import styles from './clients.scss'
import withWhirlySpan from "../withWhirlySpan.js";

class Clients extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef(); // using ref instead of rerender to avoid choppyness caused by lack of sync between scroll and rerender
		this.pRef = React.createRef();
		this.handleScroll = this.handleScroll.bind(this);
	}
	
	componentDidMount() {
		this.props.componentRef.current.style.setProperty('--slide-speed', "0.5s");
		this.enablePeek = true;
		this.lastSlideIndex = -1;
		this.handleScroll();
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}	
	  	
	render() {
		return (
			<div ref={this.props.componentRef} className={styles.clients}>
				<div>
					<div>
						<h1 ref={this.props.h1Ref}>Kunden<span className={this.props.visible ? styles.visible : ''}></span></h1>
					</div>
					<p ref={this.pRef}>
						Mit unserem in Stuttgart ansässigen Team erzielen wir erstklassige Softwarequalität in kurzen Entwicklungszyklen und sind vor Ort für Sie da.
					</p>
					<ul>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		)
	}
	
	getSlideIndexFromScrollPosition(scrollTop, startTop) {
		const rem = parseInt(getComputedStyle(document.documentElement).fontSize);
		const stepHeight = 67.3 * rem;

		if (scrollTop < startTop + stepHeight * 1 / 6) {
			return 0;
		}
		if (scrollTop < startTop + stepHeight * 4 / 3) {
			return 1;
		}
		else {
			return 2;
		}
	}
	
	getScrollPositionFromSlideIndex(slideIndex) {
		const rem = parseInt(getComputedStyle(document.documentElement).fontSize);
		const stepHeight = 67.3 * rem;
		
		switch (slideIndex) {
		case 0:
			return 0;
		case 1:
			return stepHeight * 1 / 6;
		case 2:
			return stepHeight * 4 / 3;
		default:
			// shouldn't happen
		}
	}
		
	handleScroll() { // no event object available for scroll events
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const startTop = this.props.componentRef.current.offsetTop; // from withWhirlySpan
		const slideIndex = this.getSlideIndexFromScrollPosition(scrollTop, startTop);
		
//		console.log(slideIndex);

		const node = this.pRef.current;

		if (scrollTop < startTop - document.documentElement.clientHeight) {
			this.enablePeek = true;
		}

		if (this.enablePeek) {
			if (slideIndex == 0) {
				node.style.setProperty('--peek-offset', '0rem');
				this.props.componentRef.current.classList.add(styles['slide1']);
				this.props.componentRef.current.classList.remove(styles['slide2']);
			}
			else if (slideIndex >= 1) {
				node.style.setProperty('--speed', "0.25s");
				const offset = Math.max(0, scrollTop - startTop - this.getScrollPositionFromSlideIndex(1));
//				console.log(offset);
		
				node.style.setProperty('--peek-offset', (-Math.floor(Math.log(offset/100+1)*5)) + "rem");
			}
		}
		
		if (this.lastSlideIndex != slideIndex) {
			// new slide. smooth scroll into view
			const up = slideIndex < this.lastSlideIndex;
			this.lastSlideIndex = slideIndex;
	
//			console.log(slideIndex);
			
			node.style.setProperty('--speed', "0.5s");
			node.style.setProperty('--peek-offset', "0rem");
//			console.log("slide");
	
			if (up) {
				node.classList.add(styles['up']);
				this.props.componentRef.current.classList.remove(styles['slide2']);
				this.props.componentRef.current.classList.add(styles['slide1']);
			}
			else {
				node.classList.remove(styles['up']);
				if (slideIndex == 2) {
					this.enablePeek = false;
				}
				this.props.componentRef.current.classList.remove(styles['slide1']);
				this.props.componentRef.current.classList.remove(styles['slide2']);
				this.props.componentRef.current.classList.add(styles['slide' + slideIndex]);
			}

		}
		
	}
		
}

const ClientsWithWhirlySpan = withWhirlySpan(Clients);

export default ClientsWithWhirlySpan;