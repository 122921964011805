import React, { Component } from 'react'
import styles from './services.scss'
import withWhirlySpan from "../withWhirlySpan.js";

class Services extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		return (
				<div ref={this.props.componentRef} className={styles.services}>
					<h1 ref={this.props.h1Ref}>Leistungen<span className={this.props.visible?styles.visible:''}></span></h1>
					<p>
						Wir entwickeln für Sie eine Webanwendung für Desktop, Mobile oder Ihr eingebettetes System, 
						machen Ihre Schnittstelle über einen Webservice verfügbar oder erstellen für Sie eine 
						Software zur transaktionssicheren Verknüpfung Ihrer Softwarekomponenten und Verarbeitung Ihrer Daten 
						unter Berücksichtigung Ihrer Businesslogik.      	
					</p>
				</div>
		)
	}
}

const ServicesWithWhirlySpan = withWhirlySpan(Services);

export default ServicesWithWhirlySpan;