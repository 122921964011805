import React, { Component } from 'react'
import { Link } from "react-router-dom"
import styles from './header.scss'

class Header extends Component {
  render() {
    return (
      <div className={styles.header}>
    	<a href="#" onClick={this.props.logoClickHandler}>Techunity</a>
      	<a href="#">DE</a>
      	<a href="#">EN</a>
      	<a href="#" onClick={this.props.contactClickHandler}>Kontakt</a>
      </div>
    )
  }
}

export default Header;