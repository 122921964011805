import React, { Component } from 'react'
import styles from './slides.scss'

class Slides extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef(); // using ref instead of rerender to avoid choppyness caused by lack of sync between scroll and rerender
		this.reset = this.reset.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		
		this.state = {slidedate: new Date()};
	}
	
	componentDidMount() {
		this.lastSlideMillis = Date.now();
		this.myRef.current.style.setProperty('--slide-speed', "0.5s");
		this.enablePeek = true;
		this.lastSlideIndex = -1;
		this.handleScroll();
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}	
	
	reset() {
		this.myRef.current.style.setProperty('--slide-speed', "0s");
		this.enablePeek = true;
		this.lastSlideIndex = 1;
		const node = this.myRef.current;
		node.classList.remove(styles['slide1']);
		node.classList.remove(styles['slide2']);
		node.classList.remove(styles['slide3']);
		node.classList.add(styles['slide1']);
		node.classList.add(styles['slide1additive']);
		this.handleScroll();
	}
	  
	render() {
		return (
			<div ref={this.myRef} className={styles.slides}>
				<div>
					<div>
						<div>
							<div>
								<span>Sie benötigen eine</span>
								<span>webbasierte</span>
								<span>Benutzerschnittstelle</span>
								<span>für Ihr Backendsystem</span>
							</div>
						</div>
					</div>
					
					<div>
						<div>
							<div>
								<span>Sie müssen</span>
								<span>Informationen in</span>
								<span>verteilten Systemen</span>
								<span>verarbeiten</span>
							</div>
						</div>
					</div>
						
					<div>
						<div>
							<div>
								<span>Sie möchten</span>
								<span>Daten in</span>
								<span>komplexen Prozessen</span>
								<span>transformieren</span>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		)
	}
	
	getSlideIndexFromScrollPosition(scrollTop) {
		const rem = parseInt(getComputedStyle(document.documentElement).fontSize);
		const stepHeight = 67.3 * rem;

//		if (scrollTop < stepHeight / 20) {
		if (scrollTop < stepHeight * 4 / 3) {
			return 1;
		}
//		else if (scrollTop >= stepHeight / 20 && scrollTop < stepHeight * 0.5) {
		else if (scrollTop >= stepHeight * 4 / 3 && scrollTop < stepHeight * 11 / 3) {
			return 2;
		}
		else {
			return 3;
		}
	}
	
	getScrollPositionFromSlideIndex(slideIndex) {
		const rem = parseInt(getComputedStyle(document.documentElement).fontSize);
		const stepHeight = 67.3 * rem;
		
		switch (slideIndex) {
		case 1:
			return 0;
		case 2:
//			return stepHeight / 20;
			return stepHeight * 7 / 3;
//			return stepHeight * 100 / 3;
		case 3:
//			return stepHeight * 0.5;
			return stepHeight * 100 / 3;
		default:
			// shouldn't happen
		}
	}
	
	handleScroll(event) {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const slideIndex = this.getSlideIndexFromScrollPosition(scrollTop);
		
		const offset = Math.max(0, scrollTop - this.getScrollPositionFromSlideIndex(slideIndex));
		
//		console.log(offset);
		
		const node = this.myRef.current;

		if (scrollTop == 0) {
			this.enablePeek = true;
			node.classList.remove(styles['slide2additive']);
			node.classList.remove(styles['slide3additive']);
		}
		
		if (this.lastSlideIndex == slideIndex) {
			// still on same slide
			// hard scroll to peek
			if (Date.now() - this.lastSlideMillis > 500 && offset >= 0 && this.enablePeek) {
				// finished slow slide. set to fast
//				console.log("peek");
				node.style.setProperty('--slide-speed', "0s");
				if (slideIndex == 1) {
					node.style.setProperty('--slide-peek-offset', (-Math.floor(Math.log(offset/10+1)*5)) + "rem");
				}
				else {
					node.style.setProperty('--slide-peek-offset', (-Math.floor(Math.log(offset/16+1)*3)) + "rem");
				}
			}
		}
		else {
			// new slide. smooth scroll into view
			const up = slideIndex < this.lastSlideIndex;
			this.lastSlideIndex = slideIndex;
	
//			console.log(slideIndex);
			
			node.style.setProperty('--slide-speed', "0.5s");
			node.style.setProperty('--slide-peek-offset', "0rem");
			this.lastSlideMillis = Date.now();
//			console.log("slide");
	
			if (up) {
				node.classList.add(styles['up']);
				this.enablePeek = false;
			}
			else {
				node.classList.remove(styles['up']);
				this.enablePeek = true;
			}

			node.classList.remove(styles['slide1']);
			node.classList.remove(styles['slide2']);
			node.classList.remove(styles['slide3']);
			node.classList.add(styles['slide' + slideIndex]);
			node.classList.add(styles['slide' + slideIndex + 'additive']);
		}
	}
	
}

export default Slides;