import React, { Component } from 'react'

const withWhirlySpan = (WrappedComponent) => {
	class HOC extends Component {
		constructor(props) {
			super(props);
			this.componentRef = React.createRef();
			this.h1Ref = React.createRef();
			
			this.handleScroll = this.handleScroll.bind(this);
			
			this.state = {visible: false};
		}
	
		componentDidMount() {
			this.handleScroll();
			window.addEventListener('scroll', this.handleScroll);
		}
	
		componentWillUnmount() {
			window.removeEventListener('scroll', this.handleScroll);
		}	
	
		handleScroll() {
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	
			if (scrollTop + document.documentElement.clientHeight >= window.pageYOffset + this.h1Ref.current.getBoundingClientRect().top) {
			    this.setState({visible: true});
			}
			if (scrollTop + document.documentElement.clientHeight < window.pageYOffset + this.componentRef.current.getBoundingClientRect().top) {
			    this.setState({visible: false});
			}
		}
		
		render() {
			return (
					<WrappedComponent {...this.props} componentRef={this.componentRef} h1Ref={this.h1Ref} visible={this.state.visible} />
			);
		}
	}
	    
	return HOC;
}

export default withWhirlySpan;