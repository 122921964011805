import React, { Component } from 'react'
import {
	  BrowserRouter as Router,
	  Switch,
	  Route
	} from "react-router-dom"
import styles from './app.scss'
import Header from '../header'
import Slides from '../slides'
import Services from '../services'
import Technologies from '../technologies'
import Clients from '../clients'
import Contact from '../contact'
import Footer from '../footer'
import Impressum from '../impressum'
import Datenschutzerklaerung from '../datenschutzerklaerung'

class App extends Component {
	constructor(props) {
		super(props);
		this.slidesScrollRef = React.createRef();
		this.contactScrollRef = React.createRef();
		this.handleHeaderLogoClick = this.handleHeaderLogoClick.bind(this);
		this.handleHeaderContactClick = this.handleHeaderContactClick.bind(this);
	}

	handleHeaderLogoClick(event) {
		event.preventDefault();
		window.scrollTo(0, 0);
		this.slidesScrollRef.current.reset();
	}

	handleHeaderContactClick(event) {
		event.preventDefault();
		this.contactScrollRef.current.scrollIntoView();
	}

	render() {
		return (
				<div className={styles.app}>
					<Switch>
						{/* If the current URL is /impressum, this route is rendered
						while the rest are ignored */}
						<Route path="/impressum">
							<Header />
							<Impressum />								
							<Footer />
						</Route>

						<Route path="/datenschutzerklaerung">
							<Header />
							<Datenschutzerklaerung />								
							<Footer />
						</Route>

						{/* Note how these two routes are ordered. The more specific
						path="/contact/:id" comes before path="/contact" so that
						route will render when viewing an individual contact */}
						<Route path="/contact/:id">
							<Contact />
						</Route>
						<Route path="/contact">
							<Clients />
						</Route>

						{/* If none of the previous routes render anything,
						this route acts as a fallback.

						Important: A route with path="/" will *always* match
						the URL because all URLs begin with a /. So that's
						why we put this one last of all */}
						<Route path="/">
							<Header logoClickHandler={this.handleHeaderLogoClick} contactClickHandler={this.handleHeaderContactClick} />
							<Slides ref={this.slidesScrollRef} />
							<Services />
							<Technologies />
							<Clients />
							<Contact ref={this.contactScrollRef} />
							<Footer />
						</Route>
					</Switch>      

				</div>
		)
	}
}

export default App;