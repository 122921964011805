import React, { Component } from 'react'
import styles from './technologies.scss'
import withWhirlySpan from "../withWhirlySpan.js";

class Technologies extends Component {
	constructor(props) {
		super(props);
		this.ulRef = React.createRef();
		this.handleScroll = this.handleScroll.bind(this);
		
		this.state = {ulVisible: false};
	}

	componentDidMount() {
		this.handleScroll();
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}	
	  	
	render() {
		return (
			<div ref={this.props.componentRef} className={styles.technologies}>
				<h1 ref={this.props.h1Ref}>Qualität<span className={this.props.visible ? styles.visible : ''}></span></h1>
				<p>
					Wir begleiten Sie mit Expertise, Wartung und Support über den gesamten Lebenszyklus Ihres Produkts.
      			</p>
				<h2>Wir setzen folgende Technologien ein</h2>
				<ul ref={this.ulRef} className={this.state.ulVisible ? styles['ul-visible'] : ''}> {/* cant use dot notation styles.ul-visible here, since hyphen is not a valid variable name */}
					<li>
						Java EE<br />
						Jakarta EE<br />
						Spring Boot
					</li>
					<li>
						React<br />
						JavaScript<br />
						HTML
					</li>
					<li>
						SQL, NoSQL<br />
						Message<br />
						Queuing
					</li>
					<wbr />
					<li>
						Microservices<br />
						Docker<br />
						Kubernetes
					</li>
					<li>
						REST<br />
						JSON<br />
						SOAP, XML
					</li>
					<li>
						Scrum<br />
						Agiles Projekt-<br />
						managment
					</li>
				</ul>
			</div>
		)
	}
	
	handleScroll() { // no event object available for scroll events
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

		if (scrollTop + document.documentElement.clientHeight >= window.pageYOffset + this.ulRef.current.getBoundingClientRect().top) {
		    this.setState({ulVisible: true});
		}
		if (scrollTop + document.documentElement.clientHeight < window.pageYOffset + this.props.componentRef.current.getBoundingClientRect().top) {
		    this.setState({ulVisible: false});
		}
	}	
}

const TechnologiesWithWhirlySpan = withWhirlySpan(Technologies);

export default TechnologiesWithWhirlySpan;