import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom"
import App from './components/app'
import styles from './styles/main.scss'
import './index.css'

const root = document.querySelector('#app')
//const root = document.querySelector('body')
ReactDOM.render(
		<BrowserRouter>
			<App />
		</BrowserRouter>,
		root)

if (module.hot) {
  module.hot.accept();
}