import React, { Component } from 'react'
import styles from './contact.scss'

class Contact extends Component {
	constructor(props) {
		super(props);

		this.defaultValues = {
			email: 'Ihre E-Mail-Adresse',
			phone: 'Ihre Telefonnummer',
			message: 'Ihre Nachricht',
		}

		//		this.myRef = React.createRef(); // using ref instead of rerender to avoid choppyness caused by lack of sync between scroll and rerender
		//		this.handleScroll = this.handleScroll.bind(this);

		this.state = {
			email: this.defaultValues.email,
			phone: this.defaultValues.phone,
			message: this.defaultValues.message,
			gdpr: false,
			sending: false,
			sent: false,
			error: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleInputFocus = this.handleInputFocus.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		const target = event.target;
		const value = target.name === 'gdpr' ? target.checked : target.value;
		const name = target.name;

		console.log("change " + name);

		this.setState({
			[name]: value,
			sent: false
		});
	}

	handleInputFocus(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		console.log("focus " + name);

		if (this.state[name] == this.defaultValues[name]) {
			this.setState({
				[name]: ''
			});
		}
	}

	handleInputBlur(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		console.log("blur " + name);

		if (this.state[name] == '') {
			this.setState({
				[name]: this.defaultValues[name]
			});
		}
	}

	handleSubmit(event) {
//		console.log(this.state.message);
//		console.log(this.state);

		event.preventDefault();
		
		const formData = new FormData(event.target);

		this.setState({
			sending: true,
			error: false
		});
//		fetch('https://techunity.de/cgi-bin/good.pl', {
		fetch('/cgi-bin/good.pl', {
			method: 'POST',
			headers: {
//				Accept: 'application/json',
				'Content-Type': 'multipart/form-data'
			},
			body: formData
		})
        .then(async response => {
			this.setState({sending: false});
            if (!response.ok) {
                return Promise.reject(new Error('fail'));
            }
			console.log("ok");
			this.setState({sent: true});
        })
        .catch(() => {
//        .catch(error => {
			console.log("fail");
			this.setState({
				sending: false,
				sent: false,
				error: true
			});
        });		
	}

	render() {
		return (
			<div ref={this.props.innerRef} className={styles.contact}> {/* could also have been a normal ref, since it's the top level element of this component' */}
				<form method="post" action="/cgi-bin/good.pl" onSubmit={this.handleSubmit}>
					<h1>Kontakt</h1>
					<address>
						Telefon 0711 / 2529810<br />
						Schöttlestr. 37<br />
						70597 Stuttgart
					</address>
					<p>
						Wir beraten Sie gern!
					</p>
					<input name="email" value={this.state.email} title={this.defaultValues.email} className={this.state.email == this.defaultValues.email ? styles.defaultValue : ''} onChange={this.handleChange} onFocus={this.handleInputFocus} onBlur={this.handleInputBlur} />
					<input name="phone" value={this.state.phone} title={this.defaultValues.phone} className={this.state.phone == this.defaultValues.phone ? styles.defaultValue : ''} onChange={this.handleChange} onFocus={this.handleInputFocus} onBlur={this.handleInputBlur} />
					<textarea name="message" value={this.state.message} className={this.state.message == this.defaultValues.message ? styles.defaultValue : ''} onChange={this.handleChange} onFocus={this.handleInputFocus} onBlur={this.handleInputBlur} />
					<input type="checkbox" id="gdpr" name="gdpr" checked={this.state.gdpr} onChange={this.handleChange} /><label htmlFor="gdpr">Einwilligung in Datenverarbeitung</label>
					<input type="submit" value="Senden" className={this.state.sending ? styles.greyedOut : ''} disabled={this.state.sending || this.state.sent} />
					{/* must be last because of CSS selectors */}
					<input name="subject" type="hidden" value="Anfrage an Techunity GmbH" />
					<p className={this.state.sent ? styles.shown : ''}>
						Vielen Dank!<br />
						Innerhalb eines Tages erhalten<br />
						Sie eine persönliche Antwort.
					</p>
					<p className={this.state.error ? styles.shown : ''}>
						Ihre Nachricht konnte nicht gesendet werden.<br />
						Wir bitten Sie, sich telefonisch zu melden!
					</p>
				</form>
			</div>
		)
	}
}

const ContactWithForwardRef = React.forwardRef((props, ref) => <Contact 
  innerRef={ref} {...props}
/>);

export default ContactWithForwardRef;
